import React from "react";
import {graphql} from "gatsby";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import {Col, Row} from 'react-grid-system';
import Layout from "./";
import Section from "../components/Section";
import Social from "../components/Social";


import { setConfiguration } from 'react-grid-system';

import "../sass/index.scss";

setConfiguration({gutterWidth: 32});

const SponsorPage = (props) => {
  const data = props.data.contentfulSponsor;

  return (
    <Layout>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/iex1rlv.css" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous" />
      </Helmet>
      <Section>
        <Col>
          <Row>
            <Col>
              <h1>{data.name}</h1>

              {data.sponsorRole && (
                <h2 className="color-primary">{data.sponsorRole}</h2>
              )}

              {data.image && (
                <a href={data.website}><img src={data.image.file.url} alt={data.name} style={{maxWidth: '230px'}}/></a>
              )}

              {data.website && (
                <p><strong>Website: <a href={data.website}>{data.website}</a></strong></p>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={9}>
              {data.description && (
                <div dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html
                }}
                />
              )}
            </Col>
            <Col md={3}>
              {data.whitePages && (
                <div className="margin-bottom-xl">
                  <h4>White Pages</h4>
                  <a href={data.whitePages.url} alt={data.whitePages.label}>{data.whitePages.label}</a>
                </div>
              )}

              <h4>Connect with us</h4>

              <Social {...data} />
            </Col>
          </Row>
        </Col>
      </Section>
    </Layout>
  )
};

SponsorPage.propTypes = {
  data: PropTypes.object,
};

export default SponsorPage;

export const sponsorPageQuery = graphql`
    query($slug: String!) {
        contentfulSponsor(slug: {eq: $slug}) {
            id,
            name,
            slug,
            description {
                childMarkdownRemark {
                    html
                }
            },
            image {
                file {
                    url
                }
            },
            whitePages {
              label,
							url
            },
            sponsorRole,
            website,
            twitter,
            linkedIn,
            facebook,
            instagram,
            youtube
        }
    }
`;
