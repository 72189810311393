import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './social.module.scss';

let cx = classNames.bind(styles);

function Social(props) {
  const socialClassNames = cx({
    social: true,
    socialPrimary: props.variant === 'primary',
    socialSecondary: props.variant === 'secondary'
  });

  return (
    <React.Fragment>
      {props.twitter && (
        <a href={props.twitter} className={socialClassNames} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"/></a>
      )}

      {props.facebook && (
        <a href={props.facebook} className={socialClassNames} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"/></a>
      )}

      {props.linkedIn && (
        <a href={props.linkedIn} className={socialClassNames} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"/></a>
      )}

      {props.instagram && (
        <a href={props.instagram} className={socialClassNames} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"/></a>
      )}

      {props.youtube && (
        <a href={props.youtube} className={socialClassNames} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"/></a>
      )}
    </React.Fragment>
  );
}

Social.propTypes = {
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  linkedIn:PropTypes.string,
  twitter:PropTypes.string,
  youtube:PropTypes.string,
  variant: PropTypes.string
};

export default Social;

